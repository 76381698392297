[
  {
    "names": [
      "tailwind.css"
    ],
    "generatedName": "tailwind.8584b0956884a054abee4414937fdf9b.css"
  },
  {
    "names": [
      "pureleap-web--__index.map"
    ],
    "generatedName": "-__index.40f74ef0942b916848a5421df68255cd.map.json"
  },
  {
    "names": [
      "pureleap-web--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.99785c940a7e43f487c63348e2ae0e19.js"
  },
  {
    "names": [
      "pureleap-web-posts.map"
    ],
    "generatedName": "posts.7c154568e9a7799e17a0236417467056.map.json"
  },
  {
    "names": [
      "pureleap-web-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.361b8c5b69071a728cedca9a551fea85.js"
  },
  {
    "names": [
      "pureleap-web-about.map"
    ],
    "generatedName": "about.7f114c8394c3ebf08af08327b5b31e6e.map.json"
  },
  {
    "names": [
      "pureleap-web-about-bundle.js"
    ],
    "generatedName": "about-bundle.06f7f6e85077b06fdb6907c24c50ebe7.js"
  },
  {
    "names": [
      "pureleap-web-blog.map"
    ],
    "generatedName": "blog.c5e9509a85c58366d7b2157bd5afa3f2.map.json"
  },
  {
    "names": [
      "pureleap-web-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.f2775556049ddaa93dbbb45515783bb1.js"
  },
  {
    "names": [
      "pureleap-web-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.7e74b4500c09465c749748f3c3e7167f.map.json"
  },
  {
    "names": [
      "pureleap-web-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.e2265eeaa17488f97c559abcb23a603d.js"
  },
  {
    "names": [
      "pureleap-web-robots_txt.map",
      "pureleap-web-prod-robots_txt.map"
    ],
    "generatedName": "robots_txt.e657c1c95e2a4b54e72879594aa4a2d2.map.json"
  },
  {
    "names": [
      "pureleap-web-robots_txt-bundle.js",
      "pureleap-web-prod-robots_txt-bundle.js"
    ],
    "generatedName": "robots_txt-bundle.58f543efa98d1cdc7f53eab45f882751.js"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml.map",
      "pureleap-web-prod-sitemap_xml.map"
    ],
    "generatedName": "sitemap_xml.c8bab6179b7f9ab5030465a564dfda9f.map.json"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml-bundle.js",
      "pureleap-web-prod-sitemap_xml-bundle.js"
    ],
    "generatedName": "sitemap_xml-bundle.098115e6feb6b513140080074e6dddfd.js"
  },
  {
    "names": [
      "pureleap-web-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.7b920ce0603b44b5be42db602833502d.map.json"
  },
  {
    "names": [
      "pureleap-web-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.0088abe4dfd046e09bf796529f8fc20d.js"
  },
  {
    "names": [
      "pureleap-web-_post__.map"
    ],
    "generatedName": "_post__.3537e5f573c1525ec22668ab43b69ca2.map.json"
  },
  {
    "names": [
      "pureleap-web-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.16ae9a628d93f1a7fd140dc2e091d7a8.js"
  },
  {
    "names": [
      "pureleap-web-prod--__index.map"
    ],
    "generatedName": "-__index.9cb2385ac193c350ab17d324756a6bbe.map.json"
  },
  {
    "names": [
      "pureleap-web-prod--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.49262d553e6edd04f7825dc261ca48a3.js"
  },
  {
    "names": [
      "pureleap-web-prod-about.map"
    ],
    "generatedName": "about.dbb8bd23fe5a2ee707d824a8d6d2e962.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-about-bundle.js"
    ],
    "generatedName": "about-bundle.5cc1a72383684cf3e63f2b6960a057d4.js"
  },
  {
    "names": [
      "pureleap-web-prod-blog.map"
    ],
    "generatedName": "blog.d5b4554ef755b49cd76af54511868b87.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.a9f767f5fa44907349cc8bc89b6a33b4.js"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.a556f3fc7a7f57a2880c24a0ef741d2b.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.a4a58b3932186fa307bfaff30109429b.js"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.49670c677e7f112996e65641a235479e.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.d7137f1f54e7be2daf6c8f52f159b535.js"
  },
  {
    "names": [
      "pureleap-web-prod-_post__.map"
    ],
    "generatedName": "_post__.e5fbb88cfc690ac79f443f3a218ce595.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.3d8ae84a9d5ef45c1b4da1c7a5d0ed4f.js"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub.map",
      "pureleap-web-objecthub.map"
    ],
    "generatedName": "objecthub.3db0ed1974606d0275550677fcf16fe2.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub-bundle.js",
      "pureleap-web-objecthub-bundle.js"
    ],
    "generatedName": "objecthub-bundle.1a837f97d9f64ee9630bf50ec427a963.js"
  },
  {
    "names": [
      "pureleap-web-prod-recipes.map"
    ],
    "generatedName": "recipes.a1070cbc8b555c4fd6fe7b965ba3b6ae.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.27f649ad27f45061811d39d4930ccf78.js"
  },
  {
    "names": [
      "pureleap-web-recipes.map"
    ],
    "generatedName": "recipes.24ef4ba19e3c6603ad29a51f7060a1f4.map.json"
  },
  {
    "names": [
      "pureleap-web-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.bd4dc7926f494d4a4bd1926c51ec7d20.js"
  }
]